import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import axios from "axios";
import { awsUrl2 } from "../../config";
import SmallLoader from "../common/SmallLoader";
import xlsx from "json-as-xlsx";
import NumberFormat from "react-number-format";
import numeral from "numeral";
const stateList = [
  "AK",
  "AL",
  "AR",
  "AZ",
  // "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];
const carrierMap = {
  carrier_a: "extensis-chubb (carrier_a)",
  carrier_b: "extensis-saic (carrier_b",
  carrier_c: "pride-lion ( carrier_c",
  carrier_d: "pride-snic",
  carrier_e: "security-national-insurance-company",
  carrier_f: "technology-insurance-company",
  carrier_g: "amtrust-insurance-company-of-kansas",
  carrier_h: "wesco-insurance-company",
  carrier_i: "rochdale-insurance-company",
  carrier_j: "chubb",
  carrier_k: "saic",
  carrier_l: "lion",
  carrier_m: "snic",
  carrier_n: "solve-tmic",
  carrier_o: "state-fund",
  carrier_p: "fourth-American zurich",
  carrier_q: "fourth-tic",
  carrier_r: "key risk insurance company",
  carrier_s: "united wisconsin insurance company",
  carrier_t: "hemic-tier1",
  carrier_u: "hemic-tier2",
  carrier_v: "hemic-tier3",
  carrier_w: "hemic-tier4",
  carrier_x: "hemic-tier5",
  carrier_y: "hemic-tier6",
  carrier_z: "berkley casualty company",
  carrier_aa: "carolina casualty insurance company",
  carrier_ab: "midwest employers casualty company",
  carrier_ac: "starnet insurance company",
  carrier_ad: "Preferred employers insurance company",
  carrier_ae: "sunz insurance company",
  carrier_af: "prescient national insurance company",
  carrier_ag: "Demo $25K Ded",
  carrier_ah: "Demo $50K Ded",
  carrier_ai: "Demo $75K Ded",
  carrier_aj: "Demo $100K Ded",
  carrier_ak: "Demo $250K Ded",
  carrier_al: "Demo $500K Ded",
  carrier_am: "Demo $1M Ded",
  carrier_an: "Demo $10K Ded",
  carrier_ao: "New Hampshire Insurance Company",
  carrier_ap: "Nationwide",
  carrier_aq: "Normandy",
  carrier_ar: "American Zurich Insurance Company",
  carrier_as: "Zurich American Insurance Company",
  carrier_at: "Zurich American Insurance Company Of Illinois",
  carrier_au: "Benchmark",
  carrier_av: "SUNZ 250K",
  carrier_aw: "UWIC 250K",
  carrier_ax: "Service Llyods",
};
let data = [];
const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
let currentDate = `${year}-${month}-${day}`;

const ExportToExcel = (data, fileName) => {
  let settings = {
    fileName: fileName, // Name of the resulting spreadsheet
    extraLength: 1, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    RTL: false, // Display the columns from right-to-left (the default value is false)
  };

  try {
    xlsx(data, settings); // Will download the excel file
  } catch (error) {
    console.log("error while generating excel: ", error);
  }
};
const ManualRate = ({ ManualRateGroupCheck, tableName }) => {
  const [stateListMap, setStateListMap] = useState(data);

  const [selectEffectiveDate, setSelectEffectiveDate] = useState(currentDate);
  const [stateSelected, setStateSelected] = useState();
  const [carrierSelected, setCarrierSelected] = useState();
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [zipCodeValue, setZipCodeValue] = useState();
  const [selectedZipCode, setSelectedZipCode] = useState(false);

  useEffect(() => {
    getAllState();
  }, []);

  const getAllState = () => {
    let statesDropDownList = [{ value: "Add all", label: "Add all" }];
    let stateListObject = [
      ...statesDropDownList,
      ...stateList.map((state) => {
        return { value: state.toLowerCase(), label: state };
      }),
    ];
    setStateListMap(stateListObject);
  };

  const generateExcelData = (ManulrateCalObj) => {
    try {
      // console.log(ManulrateCalObj);
      if (ManualRateGroupCheck) {
        return [
          {
            sheet: "Manual Rate",
            columns: [
              { label: "State", value: "State" },
              { label: "Class Code", value: "classCode" },
              { label: "Manual Rate", value: "ManualRateValue" },
            ],

            content: ManulrateCalObj,
          },
        ];
      } else {
        return [
          {
            sheet: "Manual Rate",
            columns: [
              { label: "State", value: "State" },
              {
                label: "Adoption Date",
                value: "adoptionDate",
              },
              { label: "Class Code", value: "classCode" },
              { label: "Lcm Value", value: "lcmValue" },

              { label: "LC Value", value: "lcvalue" },
              { label: "Manual Rate", value: "ManualRateValue" },
              { label: "lc_date", value: "lc_date" },
              { label: "territory_group", value: "territory_group" },
              { label: "fund_rate", value: "fund_rate" },
            ],

            content: ManulrateCalObj,
          },
        ];
      }
    } catch (error) {}
  };
  const handleStateDropDownChange = (e, selectedOption) => {
    // console.log(selectedOption);

    if (
      selectedOption.action == "select-option" &&
      selectedOption.option["value"] == "Add all"
    ) {
      // console.log("selectedoption");
      setStateSelected(stateListMap);
    } else if (
      selectedOption.action == "deselect-option" &&
      selectedOption.option["value"] == "Add all"
    ) {
      setStateSelected([]);
      // console.log("deselected");
    } else {
      setStateSelected(e);
    }
  };
  const handleCarrierDropDownChange = (e) => {
    // console.log(e.target.value);
    setCarrierSelected(e.target.value);
  };
  const handleEffectiveDate = (e) => {
    setSelectEffectiveDate(e.target.value);
  };
  const handleZipcodeChange = (e) => {
    setZipCodeValue(e.target.value);
  };
  const handleCheckboxChange = (e) => {
    setSelectedZipCode(!selectedZipCode);
  };

  const handleManualRateExcel = async (event) => {
    event.preventDefault();
    setLoaderStatus(true);

    let carrierStateDateobj;
    if (selectEffectiveDate && carrierSelected)
      carrierStateDateobj = {
        state:
          tableName === "ManualRateCA"
            ? [{ value: "ca", label: "ca" }]
            : stateSelected,
        carrier: carrierSelected,
        date: selectEffectiveDate,
        zipCode: zipCodeValue,
        selectedZipCode: selectedZipCode,
      };
    axios
      .post(awsUrl2 + "/api/ManualRateExcel", carrierStateDateobj)
      .then((res) => {
        let dataReceivedFromManaulRate = res.data;
        // console.log(dataReceivedFromManaulRate, "data reicja ");
        // console.log(dataReceivedFromManaulRate.lcData, "lcdata");
        let ManulrateCalObj = [];
        let lcmValue;
        let adoptionDate;
        let LcmTableClassCodeMap;
        let relevantFundRate;
        let modifiedFundRate;
        for (let keyvalues of dataReceivedFromManaulRate) {
          // console.log(keyvalues, "key of indiviual");

          if (typeof keyvalues.lcmData === "object") {
            lcmValue = keyvalues.lcmData;
            // for lcm object

            for (let lcmKey in lcmValue) {
              LcmTableClassCodeMap = keyvalues.LcmTableClassCodePromise;
              adoptionDate = keyvalues.adoptionData;

              if (carrierSelected === "carrier_p") {
                if (typeof keyvalues.fundRate === "object") {
                  relevantFundRate = keyvalues.fundRate.find((fund) => {
                    const fundYear = fund.effective_date.slice(0, 4); // Extract year
                    const adoptionYear = adoptionDate.slice(0, 4); // Extract year
                    return fundYear === adoptionYear;
                  });
                  modifiedFundRate =
                    relevantFundRate &&
                    Number(relevantFundRate.modified_fund_rate);
                }
              }

              for (let key of keyvalues.lcData) {
                // here where ii get the all the details
                // console.log(key, "++++++++++++++++++++");
                let makeClasscodeAsNumber = Number(
                  LcmTableClassCodeMap[key?.class_code]
                );
                let FinalLcm = makeClasscodeAsNumber || lcmValue[lcmKey];
                let manualRateValue = (FinalLcm * key.loss_cost).toFixed("2");

                let PrepareManaulRate = {
                  lcmValue: FinalLcm,
                  adoptionDate: adoptionDate,
                  ManualRateValue: modifiedFundRate
                    ? numeral(manualRateValue * modifiedFundRate).format("0.00")
                    : manualRateValue,
                  classCode: key.class_code,
                  State: key.state,
                  lcvalue: key.loss_cost,
                  lc_date: key.lc_date,
                  fund_rate: modifiedFundRate,
                  territory_group: lcmKey,
                };
                ManulrateCalObj.push(PrepareManaulRate);
              }
            }
          } else {
            lcmValue = keyvalues.lcmData;
            LcmTableClassCodeMap = keyvalues.LcmTableClassCodePromise;
            adoptionDate = keyvalues.adoptionData;

            if (carrierSelected === "carrier_p") {
              if (typeof keyvalues.fundRate === "object") {
                relevantFundRate = keyvalues.fundRate.find((fund) => {
                  const fundYear = fund.effective_date.slice(0, 4); // Extract year
                  const adoptionYear = adoptionDate.slice(0, 4); // Extract year
                  return fundYear === adoptionYear;
                });
                modifiedFundRate =
                  relevantFundRate &&
                  Number(relevantFundRate.modified_fund_rate);
              }
            }

            for (let key of keyvalues.lcData) {
              // here where ii get the all the details
              // console.log(key, "++++++++++++++++++++");
              let makeClasscodeAsNumber = Number(
                LcmTableClassCodeMap[key?.class_code]
              );

              console.log(makeClasscodeAsNumber, "makeClasscodeAsNumber");
              let FinalLcm = makeClasscodeAsNumber || lcmValue;
              let manualRateValue = (FinalLcm * key.loss_cost).toFixed("2");
              let PrepareManaulRate = {
                lcmValue: FinalLcm,
                adoptionDate: adoptionDate,
                ManualRateValue: modifiedFundRate
                  ? numeral(manualRateValue * modifiedFundRate).format("0.00")
                  : manualRateValue,
                classCode: key.class_code,
                State: key.state,
                lcvalue: key.loss_cost,
                lc_date: key.lc_date,
                fund_rate: modifiedFundRate,
              };
              // skip if manual Rate is NAN
              if (PrepareManaulRate.ManualRateValue === "NaN") {
                continue;
              }
              ManulrateCalObj.push(PrepareManaulRate);
            }
          }
        }

        // console.log(dataReceivedFromManaulRate, ":lcmValue");

        let response = generateExcelData(ManulrateCalObj);
        console.log("suraj response: ", response);
        if (response) {
          ExportToExcel(response, `${carrierSelected}_${selectEffectiveDate}`);
        } else {
          console.log("error while generating the data");
        }
        // console.log(ManulrateCalObj);

        setLoaderStatus(false);
      })
      .catch((error) => {
        console.error("Error in Axios request:", error);
      });

    // console.log(selectEffectiveDate, stateSelected[0].value, carrierSelected);
  };
  return (
    <form onSubmit={handleManualRateExcel}>
      <div className="mt-3 mb-4 d-flex">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <b>
            Date<span className="mandate">*</span>
          </b>

          <input
            type="date"
            style={{ width: "8rem", height: "1px" }}
            id="lcDateSelected"
            name="lcDateSelected"
            value={selectEffectiveDate}
            onChange={handleEffectiveDate}
          />
        </div>
        <div
          style={{
            marginLeft: "22px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <b>
            {"Carrier"}
            <span className="mandate">*</span>
          </b>
          <select
            id="carrier-type"
            value={carrierSelected}
            onChange={(e) => {
              handleCarrierDropDownChange(e);
            }}
            style={{ width: "7rem" }}
          >
            <option selected disabled value={"Please Select"}>
              {"Please Select"}
            </option>
            {Object.keys(carrierMap).map((carrier) => {
              return (
                <option key={carrier} value={carrier}>
                  {carrierMap[carrier]}
                </option>
              );
            })}
          </select>
        </div>
        {tableName === "ManualRateCA" ? (
          <div
            style={{
              marginLeft: "22px",
              display: "flex",
              flexDirection: "column",
              marginTop: "-11px",
            }}
          >
            <label>
              <input
                type="checkbox"
                checked={selectedZipCode}
                onChange={handleCheckboxChange}
              />
              Check this box to enable zipcode search
            </label>

            <b>
              Zip code<span className="mandate">*</span>
            </b>
            <NumberFormat
              placeholder="zipCode"
              autoComplete="off"
              Value={zipCodeValue}
              format="#####"
              onChange={handleZipcodeChange}
              disabled={!selectedZipCode}
            />
          </div>
        ) : (
          <div
            style={{
              marginLeft: "22px",
              display: "flex",
              flexDirection: "column",
              marginTop: "-11px",
            }}
          >
            <b>
              {"State"}
              <span className="mandate">*</span>
            </b>
            <ReactMultiSelectCheckboxes
              style={{ width: "6rem", height: "1px" }}
              className="react-select-checkbox"
              classNamePrefix="react-select-checkbox"
              value={stateSelected}
              options={stateListMap}
              onChange={handleStateDropDownChange}
            />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-outline-primary ManualRateDownloadButton"
        >
          Download
        </button>
        {loaderStatus && <SmallLoader />}
      </div>
    </form>
  );
};

export default ManualRate;
